import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/browser';
import { config } from './config';
import { getStoredUtmParams } from '../../utils/campaigns';

export const trackEvent = async ({ state }, payload) => {
  let deviceId = localStorage.getItem('deviceId');

  // Retrieve the entire userDetails object from local storage
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  if (!deviceId) {
    deviceId = state.chatRandomUserId;
    localStorage.setItem('deviceId', deviceId);
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent?.toLowerCase()
  );

  const utmParams = getStoredUtmParams();

  const data = JSON.stringify({
    event: payload.event,
    content: payload.content,
    values: payload.values,
    client_id: userDetails.uid,
    device_id: deviceId,
    user_id: userDetails.uid,
    email: userDetails.email,
    is_registered: !!userDetails.uid,
    origin_id: state.userOriginId,
    user_platform: isMobile ? 'mobile' : 'desktop',
    gpt4_mode: state.chatGptMode,
    elaborate_mode: state.chatElaborateMode,
    is_email_verified: userDetails.isUserVerified,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    institution: userDetails.institution,
    occupation: userDetails.occupation,
    country: userDetails.country,
    userLanguage: userDetails.language ?? 'en',
    utm_source: utmParams?.utm_source ?? '',
    utm_medium: utmParams?.utm_medium ?? '',
    utm_campaign: utmParams?.utm_campaign ?? '',
  });

  const axiosConfig = {
    method: 'post',
    url: `${config.apiBaseUrl}/mixpanel`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  axios
    .request(axiosConfig)
    .then(() => {
      // Response handling
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const initializeChatRandomUserId = async ({ commit }) => {
  let chatRandomUserId = localStorage.getItem('chatRandomUserId');
  if (!chatRandomUserId) {
    // Generate a new UUID if not present in localStorage
    chatRandomUserId = uuidv4();
    localStorage.setItem('chatRandomUserId', chatRandomUserId);
  }
  commit('SET_STATE_PROPERTY', {
    property: 'chatRandomUserId',
    value: chatRandomUserId,
  });

  // console.log('chatRandomUserId: ', chatRandomUserId);
};
