const FILE_STATUS = {
  PREPARING: 'preparing',
  UPLOADING: 'uploading',
  HASHING: 'hashing',
  DUPLICATE_NAME: 'duplicate_name',
  DUPLICATE_CONTENT: 'duplicate_content',
  SIZE_PRECONDITION_FAILED: 'size_precondition_failed',
  TYPE_PRECONDITION_FAILED: 'type_precondition_failed',
  ILLEGAL_TYPE: 'illegal_type',
  MAXIMUM_SIZE_EXCEEDED: 'maximum_size_exceeded',
  HASH_REJECTED: 'hash_rejected',
  UPLOAD_FAILED: 'upload_failed',
  UPLOADED: 'uploaded',
  HASH_FAILED: 'hashing_failed',
  NOTIFY_INGEST_FAILED: 'notify_ingest_failed',
  UPSERT_STARTED: 'upsert_started',
  UPSERT_FAILED: 'upsert_failed',
  UPSERT_FINISHED: 'upsert_finished',
  EMBEDDING_STARTED: 'embedding_started',
  EMBEDDING_FAILED: 'embedding_failed',
  EMBEDDING_FINISHED: 'embedding_finished',
  PARSING_STARTED: 'parsing_started',
  PARSING_FAILED: 'parsing_failed',
  PARSING_FINISHED: 'parsing_finished',
  QUEUEING_STARTED: 'queueing_started ',
  QUEUEING_FAILED: 'queueing_failed',
  QUEUEING_FINISHED: 'queueing_finished',
  QUOTA_EXCEEDED: 'quota_exceeded',
  BATCHING_FAILED: 'batching_failed',
  SLICING_STARTED: 'slicing_started',
  SLICING_FAILED: 'slicing_failed',
};

const FILE_STATUS_DISPLAY = {
  [FILE_STATUS.PREPARING]: { icon: 'mdi-progress-helper', color: 'primary' },
  [FILE_STATUS.HASHING]: { icon: 'mdi-pound-box-outline', color: 'primary' },
  [FILE_STATUS.UPLOADING]: { icon: 'mdi-upload', color: 'primary' },
  [FILE_STATUS.DUPLICATE_NAME]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.DUPLICATE_CONTENT]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.SIZE_PRECONDITION_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.TYPE_PRECONDITION_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.ILLEGAL_TYPE]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.MAXIMUM_SIZE_EXCEEDED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.HASH_REJECTED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.HASH_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.UPLOAD_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.NOTIFY_INGEST_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.UPSERT_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.EMBEDDING_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.PARSING_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.QUEUEING_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.UPSERT_STARTED]: { icon: 'mdi-cloud-upload', color: '' },
  [FILE_STATUS.UPSERT_FINISHED]: { icon: 'mdi-cloud-check-variant', color: 'success' },
  [FILE_STATUS.EMBEDDING_STARTED]: { icon: 'mdi-memory', color: '' },
  [FILE_STATUS.EMBEDDING_FINISHED]: { icon: 'mdi-memory', color: 'primary' },
  [FILE_STATUS.PARSING_STARTED]: { icon: 'mdi-cloud-cog', color: '' },
  [FILE_STATUS.PARSING_FINISHED]: { icon: 'mdi-cloud-cog', color: 'primary' },
  [FILE_STATUS.QUEUEING_STARTED]: { icon: 'mdi-queue-first-in-last-out', color: '' },
  [FILE_STATUS.QUEUEING_FINISHED]: { icon: 'mdi-queue-first-in-last-out', color: 'primary' },
  [FILE_STATUS.UPLOADED]: { icon: 'mdi-check-circle', color: 'success' },
  [FILE_STATUS.QUOTA_EXCEEDED]: { icon: 'mdi-file-cancel', color: 'error' },
  [FILE_STATUS.BATCHING_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
  [FILE_STATUS.SLICING_STARTED]: { icon: 'mdi-bread-slice', color: 'primary' },
  [FILE_STATUS.SLICING_FAILED]: { icon: 'mdi-alert-circle', color: 'error' },
};

const PROGRESS_STATUSES_FOR_FE = [
  FILE_STATUS.PREPARING,
  FILE_STATUS.HASHING,
  FILE_STATUS.UPLOADING,
];

const NUMBER_OF_HASH_WORKERS = navigator.hardwareConcurrency ?? 4;
const NUMBER_OF_UPLOAD_WORKERS = 3;

const MAX_FILE_SIZE = 32 * 1048576; //32Mib

const isFileInProgress = (status) => PROGRESS_STATUSES_FOR_FE.includes(status);
const isFileProcessing = (status) =>
  !FAILED_STATUSES.includes(status) && status !== FILE_STATUS.UPSERT_FINISHED;

const getStatusIcon = (status) => FILE_STATUS_DISPLAY[status]?.icon || '';
const getStatusColor = (status) => FILE_STATUS_DISPLAY[status]?.color || '';

const FAILED_STATUSES = [
  FILE_STATUS.DUPLICATE_NAME,
  FILE_STATUS.DUPLICATE_CONTENT,
  FILE_STATUS.SIZE_PRECONDITION_FAILED,
  FILE_STATUS.TYPE_PRECONDITION_FAILED,
  FILE_STATUS.ILLEGAL_TYPE,
  FILE_STATUS.MAXIMUM_SIZE_EXCEEDED,
  FILE_STATUS.HASH_REJECTED,
  FILE_STATUS.UPLOAD_FAILED,
  FILE_STATUS.HASH_FAILED,
  FILE_STATUS.NOTIFY_INGEST_FAILED,
  FILE_STATUS.UPSERT_FAILED,
  FILE_STATUS.EMBEDDING_FAILED,
  FILE_STATUS.PARSING_FAILED,
  FILE_STATUS.QUEUEING_FAILED,
  FILE_STATUS.QUOTA_EXCEEDED,
  FILE_STATUS.BATCHING_FAILED,
  FILE_STATUS.SLICING_FAILED,
];

const PREPARING_STATUSES_FOR_UPLOADING_SERVER = [FILE_STATUS.PREPARING, FILE_STATUS.HASHING];
const PREPARING_STATUSES_FOR_SAVING_DOCUMENTS = [
  FILE_STATUS.SLICING_STARTED,
  FILE_STATUS.PARSING_STARTED,
  FILE_STATUS.EMBEDDING_STARTED,
  FILE_STATUS.UPSERT_STARTED,
  FILE_STATUS.QUEUEING_STARTED,
];
const FINISHED_STATUSES_FOR_SAVING_DOCUMENTS = [
  FILE_STATUS.EMBEDDING_FINISHED,
  FILE_STATUS.PARSING_FINISHED,
  FILE_STATUS.QUEUEING_FINISHED,
];

const sortDocumentsByStatusPriority = (documents) => {
  // Determine the order of the documents based on status.
  const priority = {
    [FILE_STATUS.PREPARING]: 1000,
    [FILE_STATUS.HASHING]: 900,
    [FILE_STATUS.UPLOADING]: 800,
    [FILE_STATUS.UPLOADED]: 700,
    [FILE_STATUS.SLICING_STARTED]: 650,
    [FILE_STATUS.QUEUEING_STARTED]: 600,
    [FILE_STATUS.QUEUEING_FINISHED]: 500,
    [FILE_STATUS.PARSING_STARTED]: 400,
    [FILE_STATUS.PARSING_FINISHED]: 300,
    [FILE_STATUS.EMBEDDING_STARTED]: 200,
    [FILE_STATUS.EMBEDDING_FINISHED]: 100,
    [FILE_STATUS.UPSERT_STARTED]: 90,
    [FILE_STATUS.UPSERT_FINISHED]: 80,
  };

  // Sort the documents by status.
  documents.sort((a, b) => (priority[b.status] ?? 0) - (priority[a.status] ?? 0));
  return documents;
};

const categorizeDocs = (documents) => {
  const chipStatuses = [
    {
      textKey: 'preparing',
      list: PREPARING_STATUSES_FOR_UPLOADING_SERVER,
      icon: 'mdi-progress-helper',
      color: 'primary',
    },
    { textKey: 'uploading', list: [FILE_STATUS.UPLOADING], icon: 'mdi-upload', color: 'primary' },
    {
      textKey: 'uploaded',
      list: [FILE_STATUS.UPLOADED],
      icon: 'mdi-check-circle',
      color: 'success',
    },
    {
      textKey: 'preparingToSave',
      list: PREPARING_STATUSES_FOR_SAVING_DOCUMENTS,
      icon: 'mdi-content-save-cog',
      color: 'primary',
    },
    {
      textKey: 'readyToSave',
      list: FINISHED_STATUSES_FOR_SAVING_DOCUMENTS,
      icon: 'mdi-content-save-check',
      color: 'primary',
    },
    {
      textKey: 'readyToUse',
      list: [FILE_STATUS.UPSERT_FINISHED],
      icon: 'mdi-cloud-check-variant',
      color: 'success',
    },
    { textKey: 'failed', list: FAILED_STATUSES, icon: 'mdi-alert-circle', color: 'error' },
  ];

  const categorizedDocs = new Map();
  chipStatuses.forEach((group) => {
    const list = documents.filter((doc) => group.list.includes(doc.status));
    if (list.length) {
      categorizedDocs.set(group.textKey, {
        list,
        icon: group.icon,
        total: list.length,
        color: group.color,
      });
    }
  });
  return categorizedDocs;
};
export {
  sortDocumentsByStatusPriority,
  isFileInProgress,
  isFileProcessing,
  getStatusColor,
  getStatusIcon,
  categorizeDocs,
  FILE_STATUS,
  FAILED_STATUSES,
  NUMBER_OF_HASH_WORKERS,
  NUMBER_OF_UPLOAD_WORKERS,
  MAX_FILE_SIZE,
  PROGRESS_STATUSES_FOR_FE,
};
