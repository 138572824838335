<template>
  <v-slide-y-transition
    v-if="
      hideProgressbar
        ? currentTokenPercentage > 0 && isTokenLimitExceeded
        : currentTokenPercentage > 0
    ">
    <div
      :class="{
        'token-informative-section': true,
        'token-informative-section__flex-end': !isTokenLimitExceeded,
        'token-informative-section__space-between': isTokenLimitExceeded,
      }">
      <v-slide-y-transition v-if="isTokenLimitExceeded"
        ><span :class="{ 'token-informative-section__error': coloredText }">{{
          informativeMessage
        }}</span></v-slide-y-transition
      >
      <div class="progress-bar-container">
        <v-progress-linear
          class="progress-bar-container__progress-bar"
          buffer-value="0"
          :color="isTokenLimitExceeded ? 'rgb(var(--v-theme-primary-lighten-1))' : '#DDDFEA'"
          :model-value="currentTokenPercentage"
          stream></v-progress-linear>
        <p :class="{ 'token-informative-section__error': isTokenLimitExceeded }">
          {{ currentTokenPercentage }}%
        </p>
      </div>
    </div>
  </v-slide-y-transition>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  currentTokenPercentage: {
    type: Number,
    default: 0,
  },
  isTokenLimitExceeded: {
    type: Boolean,
    default: false,
  },
  informativeMessage: {
    type: String,
    default: '',
  },
  hideProgressbar: {
    type: Boolean,
    default: false,
  },
  coloredText: {
    type: Boolean,
    default: false,
  },
});

const { currentTokenPercentage, isTokenLimitExceeded, informativeMessage, hideProgressbar } =
  toRefs(props);
</script>

<style lang="scss" scoped>
.progress-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: rem(8px);
  min-width: rem(148px);
  &__progress-bar {
    flex-grow: 1;
  }
}
.token-informative-section {
  display: flex;
  align-items: flex-start;
  &__flex-end {
    justify-content: flex-end;
  }
  &__space-between {
    justify-content: space-between;
  }
  gap: rem(8px);
  color: $color-secondary-darken-3;
  @include text14;

  &__error {
    color: $color-secondary-action-blue;
  }
}
</style>
